import { admin2 } from '@/api/instance'

// => 創建折扣碼
export const CreatePromoCode = async ({
  shopId,
  name,
  enable,
  code,
  memberUseLimit,
  shopUseLimit,
  disableProductActivity,
  disableStorewideActivity,
  requirementLogic,
  promoContents,
  promoRequirements,
  productRangeType,
  productRangeByProducts,
  productRangeByActivities,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommercePromoCode`,
    data: {
      name,
      enable,
      code,
      memberUseLimit,
      shopUseLimit,
      disableProductActivity,
      disableStorewideActivity,
      requirementLogic,
      promoContents,
      promoRequirements,
      productRangeType,
      productRangeByProducts,
      productRangeByActivities,
    },
  })
  return res
}

// => 取得折扣碼
export const GetPromoCode = async ({ shopId, start, limit, name, enable }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePromoCode`,
    params: {
      start,
      limit,
      name,
      enable,
    },
  })
  return res
}

// => 取得折扣碼數量
export const GetPromoCodeCount = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePromoCode/count`,
  })
  return res
}

// => 取得特定折扣碼
export const FindPromoCode = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePromoCode/${id}`,
  })
  return res
}

// => 更新折扣碼
export const UpdatePromoCode = async ({
  shopId,
  id,
  name,
  enable,
  code,
  memberUseLimit,
  shopUseLimit,
  disableProductActivity,
  disableStorewideActivity,
  requirementLogic,
  promoContents,
  promoRequirements,
  productRangeType,
  productRangeByProducts,
  productRangeByActivities,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommercePromoCode/${id}`,
    data: {
      name,
      enable,
      code,
      memberUseLimit,
      shopUseLimit,
      disableProductActivity,
      disableStorewideActivity,
      requirementLogic,
      promoContents,
      promoRequirements,
      productRangeType,
      productRangeByProducts,
      productRangeByActivities,
    },
  })
  return res
}

// => 刪除折扣碼
export const DeletePromoCode = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommercePromoCode/${id}`,
  })
  return res
}
